import React, { useState } from 'react'
import Image from 'react-image-enlarger'

const ZoomableImage = ({ title, thumb, full }) => {
    const [zoomed, setZoomed] = useState(false)

    return (
        <>
            <Image
                key={title}
                border="0"
                alt={title}
                title={title}
                src={thumb}
                enlargedSrc={full}
                zoomed={zoomed}
                onClick={() => setZoomed(true)}
                onRequestClose={() => setZoomed(false)}
                style={{ maxWidth: "auto" }}
                loading="lazy"
            />
        </>
    )
}

export default ZoomableImage
