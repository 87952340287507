/* eslint no-unused-vars: ["warn", { "varsIgnorePattern": "^_", "argsIgnorePattern": "^_" }] */

import React, { useState } from 'react'

import * as c from './collectionConstants.jsx'
import ItemImage from './ItemImage.jsx'
import ItemQty from './ItemQty.jsx'
import ItemRarenessCell from './ItemRarenessCell.jsx'
import GuideLink from './GuideLink.jsx'

import './ItemRow.css'

// A single row in the itemtable
const ItemRow = ({
    id, item, itemType, have: initHave, incoming: initIncoming, view, prefs,
    typeDisplay, search, userInfo, itemSetCounts, rareness, new_set, pageType,
    itemSetImages, solicitHelp, switchToGuide, currentMemberKey,
}) => {
    const [qty, setQty] = useState(initHave)
    const [incoming, setIncoming] = useState(initIncoming)

    console.info("Rendering ItemRow")

    let no_colors = (view === c.View.SELF && userInfo['logged_in'] === 0)

    const currentPrefs = prefs[currentMemberKey]

    // our child hands us e.target.value already
    function handleHaveChange(qty) {
        setQty(qty)
    }

    // our child hands us e.target.value already
    function handleIncomingChange(qty) {
        setIncoming(qty)
    }

    // Internal function used by getClass that simply turns a qty
    // into a class name, withut taking into account transitions
    function _getClass(qty) {
        if (no_colors) {
            return ['', 'visible']
        }

        let cls, vis
        let iqty = parseInt(qty)
        let desired = parseInt(currentPrefs[`${itemType}s_num_desired`])
        if (qty === 'Extra' || iqty > desired) {
            cls = "extra"
            vis = typeDisplay.extra ? "visible" : "collapse"
        } else if (iqty === 0) {
            cls = "missing"
            vis = typeDisplay.missing ? "visible" : "collapse"
        } else if (iqty < desired) {
            cls = "incomplete"
            vis = typeDisplay.incomplete ? "visible" : "collapse"
        } else if (iqty === desired) {
            cls = "complete"
            vis = typeDisplay.complete ? "visible" : "collapse"
        }
        return [cls, vis]
    }

    // given a qty and incoming returns the correct CSS class (including
    // transitional ones) and the visibility
    function getClass(qty, incoming, which) {
        let cls, vis, otherClass, _otherVis
        [cls, vis] = _getClass(qty)

        if (cls !== 'extra' && incoming !== 0) {
            [otherClass, _otherVis] = _getClass(
                parseInt(qty) + parseInt(incoming)
            )
            if (otherClass !== cls) {
                cls = cls + "_to_" + otherClass
            }
        }
        if (which === 'class') {
            return cls
        } else {
            return vis
        }
    }

    // Given qty and search critiera return visibility
    const visibility = (qty, incoming, search) => {
        let vis = getClass(qty, incoming, 'vis')

        // If we're already not visible, done...
        if (vis === 'collapse') {
            return vis
        }

        // if the search is now blank, we aren't limiting...
        if (search === "") {
            return vis
        }

        // Otherwise, assemble our string
        if (item['front'].toLowerCase().includes(search) ||
            item['back'].toLowerCase().includes(search) ||
            (item['note'] !== null &&
                item['note'].toLowerCase().includes(search)) ||
            (item['year'] !== null && item['year'].includes(search)) ||
            item['color'].toLowerCase().includes(search) ||
            (item['comment'] !== null &&
                item['comment'].toLowerCase().includes(search))
        ) {
            return 'visible'
        } else {
            return 'collapse'
        }
    }

    function handleTags(item) {
        let extra = <>{item['comment']}</>

        if (!('tags' in item) || item['tags'] === null || item['tags'] === '') {
            return extra
        }

        for (let tag of item['tags'].split(',')) {
            let img = null
            let guide = tag
            if (c.TAGINFO[tag]['guide']) {
                guide = c.TAGINFO[tag]['guide']
            }

            let text = c.TAGINFO[tag]['text']
            if (c.TAGINFO[tag]['img']) {
                img = (
                    <img
                        src={c.TAGINFO[tag]['img']}
                        alt={text}
                        width={c.ICON_WIDTH}
                        height={c.ICON_WIDTH}
                    />
                )
            }
            /* Within guides, updateGuide is just updateGuide() which changes the guide, but from
             * here we pass in switchToGuide()
             */
            extra = <>{extra}<br/><GuideLink updateGuide={switchToGuide} {...{guide}}>{img} {text}</GuideLink></>
        }
        return extra
    }

    let comment = handleTags(item)

    return (
        <tr
            className={`${itemType}row ` + (no_colors ? "" : getClass(qty, incoming, 'class'))}
            /* XXX: probably could turn this into a useEffect with a ref? */
            style={{visibility: visibility(qty, incoming, search)}}
        >
            <td className="year">
                {/* name here is important, it's how we get item ID for copy/paste */}
                <a name={id} className='itemlink' href={`#${itemType}${id}`} id={`${itemType}${id}`}>{ item['year'] || 'unknown' }</a><br/>
                <span className="id">ID: {id}</span>
            </td>
            {
                /* Tin/Pack Cover image */
                item['variety'] === 'picktin' && new_set &&
                <ItemImage
                    set={item['tin']}
                    count={itemSetCounts[pageType][item['tin']]}
                    coverImages={itemSetImages[pageType][item['tin']]}
                    solicitHelp={false}
                    {...{itemType}}
                />
            }
            <td className="ptype">{ item['type'] }</td>
            <td className="color">{ item['color'] }</td>
            <td className="front">{ item['front'] }</td>
            <td className="back">{ item['back'] }</td>
            <td className="note">{ item['note'] }</td>
            <ItemImage
                variety={ item['variety'] }
                numImages={ item['num_images'] }
                id={ id }
                linkify={ true }
                {...{itemType, solicitHelp}}
            />
            <ItemQty prefs={currentPrefs}
                {...{id, qty, incoming, handleHaveChange, handleIncomingChange,
                        view, userInfo, itemType}}
            />
            { pageType !== c.PageType.UNOFFICIAL &&
                <ItemRarenessCell
                    rareness={rareness[pageType][item['id']]}
                    max="8" />
            }
            <td className="comment">{ comment }</td>
        </tr>
    )
}

export default ItemRow
