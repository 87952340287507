import React, { useState, useCallback, Fragment } from 'react'

import Image from 'react-image-enlarger'
import ZoomableImage from './ZoomableImage'

import './ItemColor.css'

// The table cell with the item image in it
//
// linkify - normally true, but if false, don't make them zoom-links,
//           used for 'results' overlays
const ItemImage = ({
    itemType, numImages, variety, id, linkify, set, count, coverImages,
    solicitHelp,
}) => {
    const [zoomed, setZoomed] = useState(false)

    console.info("Rendering ItemImage")

    const imagePaths = useCallback((num) => {
        let dir = `/images/${itemType}s`

        if (numImages === 0) {
            return solicitHelp
                ? {
                    thumb: `${dir}/help-us--thumb.png`,
                    full: "/about/contribute-items.shtml",
                }
                : {
                    thumb: `${dir}/unavailable--thumb.png`,
                    full: `${dir}/unavailable.png`,
                }
        }

        if (variety === 'picktin') {
            dir += `/tins`
        } else if (['unofficial', 'chapter'].includes(variety)) {
            dir += `/unofficial`
        }

        const tail = num > 0 ? `-${num+1}` : ''
        const base = `${dir}/${id}`
        return {
            thumb: `${base}${tail}--thumb.png`,
            full: `${base}${tail}.png`,
        }
    }, [itemType, id, numImages, variety, solicitHelp])

	const renderLinkedImage = (i, isLast) => {
		const { thumb, full } = imagePaths(i)
		const title = i === 0 ? `${itemType}${id}` : `${itemType}${id} - ${i + 1}`

		return (
			<Fragment key={title}>
				<ZoomableImage title={title} thumb={thumb} full={full} />
				{!isLast && <br />}
			</Fragment>
		)
	}

    const renderPlainImage = (i) => {
        const { thumb } = imagePaths(i)
        const title = i === 0 ? `${itemType}${id}` : `${itemType}${id} - ${i + 1}`

        return (
            <img
                key={title}
                alt={title}
                title={title}
                src={thumb}
                loading="lazy"
            />
        )
    }

    if (set) {
        return (
            <td className="tin center" rowSpan={count}>
                {set}<br />
                <Image
                    border="0"
                    alt={set}
                    title={set}
                    src={coverImages.thumb}
                    enlargedSrc={coverImages.images}
                    zoomed={zoomed}
                    onClick={() => setZoomed(true)}
                    onRequestClose={() => setZoomed(false)}
                    loading="lazy"
                />
            </td>
        )
    }

    if (linkify) {
        if (numImages === 0 && solicitHelp) {
            const { thumb, full } = imagePaths(null)
            return (
                <td className="colored center image">
                    <a href={full}>
                        <img src={thumb} alt="no scan available" />
                    </a>
                </td>
            )
        }

        return (
            <td className="colored center image">
				{[...Array(numImages)].map((_, i) => renderLinkedImage(i, i === numImages - 1))}
            </td>
        )
    }

    return (
        <td className="colored center image">
            {[...Array(numImages)].map((_, i) => renderPlainImage(i))}
        </td>
    )
}

export default ItemImage
